<template>
    <div class="auth-login">
        <div class="page-container">
            <a class="logo">
                <img src="../../assets/img/logo-new.png" />
                <span>浙江永达</span>
            </a>
            <div class="input-container">
                <van-icon name="user-o" />
                <input v-model="loginForm.name" placeholder="请输入用户名" />
            </div>
            <div class="input-container">
                <van-icon name="edit" />
                <input v-model="loginForm.password" type="password" placeholder="请输入密码" />
            </div>
            <van-button block class="submit-button" color="#df5f0b" @click="onLogin()">登录系统</van-button>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { AUTH_LOGIN } from "@/api/auth";
import usePublic from "../../hooks/usePublic";
import { Toast } from "vant";

let { $route, $router, $store } = usePublic();

let loginForm = ref({
    name: "",
    password: ""
});

let onLogin = () => {
    AUTH_LOGIN({ ...loginForm.value }).then((res) => {
        if (res.data.code === 200) {
            $store.commit("setToken", res.data.access_token);
            $store.dispatch("actionUserData").then((userData) => {
                console.log(111, userData);
                Toast({
                    type: "success",
                    message: "登录成功，即将为您跳转！",
                    onClose: () => {
                        if (userData.roles === "资产客户") {
                            $router.push({ name: "ClientIndex" });
                        } else if (userData.roles === "厂商") {
                            $router.push({ name: "CompanyIndex" });
                        } else if (userData.roles === "施工单位") {
                            $router.push({ name: "ConstructionIndex" });
                        } else if (userData.roles === "巡护单位") {
                            $router.push({ name: "PatrolIndex" });
                        }
                    }
                });
            });
        }
    });
};
</script>

<style lang="scss" scoped>
.auth-login {
    width: 100vw;
    height: 100vh;
    background: url("https://sky-1251058522.file.myqcloud.com/%E8%83%8C%E6%99%AF.png") no-repeat center;
    background-size: cover;

    .page-container {
        width: 100%;
        height: 100%;
        padding-top: 200px;
        background: rgba(#000000, 0.6);

        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            img {
                display: block;
                width: 88px;
            }

            span {
                margin-left: 15px;
                font-size: 40px;
                font-weight: bold;
                color: #d92821;
            }
        }

        .input-container {
            display: flex;
            align-items: center;
            width: 320px;
            margin: 20px auto 0 auto;
            padding: 0 15px;
            background: rgba(#fff, 0.5);
            border-radius: 10px;

            .van-icon {
                font-size: 20px;
                color: #df5f0b;
            }

            input {
                display: block;
                width: 260px;
                line-height: 45px;
                margin-left: 5px;
                background: none;
                border: none;
                font-size: 14px;
                color: #fff;

                &::-webkit-input-placeholder {
                    color: #ffffff;
                }
            }
        }

        .submit-button {
            width: 320px;
            margin: 20px auto 0 auto;
            border-radius: 10px;
        }
    }
}
</style>
